import React, { useEffect, useState } from "react";
import styles from './Auction.module.scss'
import { ProfileLayout } from "../../components/ProfileLayout/ProfileLayout";
import { Button, Badge, Modal, Select, Input, Divider, Skeleton, List } from "antd";
import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { autctionApi, profileAPI } from "../../api/api";
import { ReactComponent as Bid } from '../../assets/svg/profile-main-btn-svg/auction.svg'
import AuctionItem from "../../components/AuctionItem";
import moment from "moment";
import { toast } from "react-toastify";
import { sprintf } from 'sprintf-js'

const Auction = () => {
  const [currentView, setCurrentView] = useState(0);
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [myTracks, setMyTracks] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedValue, setSelectedValue] = useState<any>(null)
  const [data, setData] = useState<any>()
  const [yourAuctions, setYourAuctions] = useState<any>()
  const [startPrice, setStartPrice] = useState(null)
  const [isTracksLoading, setTracksIsLoading] = useState(true)
  const [per, setPer] = useState<number>(2);
  const [page, setPage] = useState<number>(1);
  const [perAll, setPerAll] = useState<number>(2);
  const [pageAll, setPageAll] = useState<number>(1);

  useEffect(() => {
    autctionApi
      .getAuction(perAll, pageAll)
      .then((res) => {
        setData(res)
      })
      .then(() => {
        autctionApi.getParticipatedAuction(per, page)
          .then((res) => {
            setYourAuctions(res)
          })
      })
      .then(() => {
        setTracksIsLoading(false)
      })
  }, [per, page, perAll, pageAll])



  const getData = () => {
    autctionApi
    .getAuction(perAll, pageAll)
    .then((res) => {
      setData(res)
    })
    .then(() => {
      autctionApi.getParticipatedAuction(per, page)
        .then((res) => {
          setYourAuctions(res)
        })
    })
    .then(() => {
      setTracksIsLoading(false)
    })
  }

  const onJoin = () => {
    setPageAll(1)
    setPage(1)
    getData()
  }

  const startAuction = () => {
    autctionApi
      .createAuction(selectedValue.value, startPrice)
      .then(() => {
        toast.success('Auction Created')
      })
      .then(() => {
        setCreateModalOpen(false)
      })
      .then(() => {
        autctionApi
          .getAuction(perAll, pageAll)
          .then((res) => {
            setData(res)
            setSelectedValue(null)
          })
          autctionApi.getParticipatedAuction(per, page)
          .then((res) => {
            setYourAuctions(res)
          })
      })
      .catch(() => {
        toast.success('Something is wrong')

      })
  }

  const renderLabel = (label) => {

    return (
      <div className={styles.label}>
        <div>
          <img src={label.cover} alt={label.name} className={styles.label_cover} />
        </div>
        <div className={styles.details}>
          <p className={styles.select_items}><strong>Track:</strong> {label.name}</p>
          <p className={styles.select_items}>
            <strong>Artists:</strong> {label?.artists?.map((artist) => artist.name).join(', ')}
          </p>
          <p className={styles.select_items}>
            <strong>Genres:</strong> {label?.genre?.map((genre) => genre.name).join(', ')}
          </p>
          <p className={styles.select_items}><strong>Price:</strong> {label.price}</p>
        </div>
      </div>
    );
  };

  const handleModalCancel = () => {
    setSelectedValue(null)
    setCreateModalOpen(false)

  }


  useEffect(() => {
    profileAPI
      .getTrack()
      .then((res) => {
        const formattedTracks = res?.data?.map((track) => ({
          value: track.id, // замените `someProperty` на поле, которое хотите использовать как значение
          label: { cover: track?.cover, name: track?.name, artists: track?.artists_names, price: track?.price, genre: track?.categories_names } // замените `someOtherProperty` на поле, которое будет отображаться внутри <div>
        }));
        setMyTracks(formattedTracks);
      })
      .then(() => {
        setIsLoading(false)
      })
  }, [selectedValue])

  if (isLoading) {
    return <></>
  }

  return (
    <ProfileLayout>
      <div className={styles.auction_header}>
        <h2 className={styles.auction_header__text}>Auction</h2>
      </div>

      <div className={styles.action_buttons}>
        <Button onClick={() => setCurrentView(0)} className={currentView === 0 ? styles.active_button : styles.default_button}>Other Auctions</Button>
        <Badge color={'#9507FF'} className={styles.badge} count={yourAuctions?.meta?.total} overflowCount={99}>
          <Button className={currentView === 1 ? styles.active_button : styles.default_button} onClick={() => setCurrentView(1)}>My Auctions</Button>
        </Badge>
        {/* <Button onClick={() => setCreateModalOpen(true)} className={styles.create_button}><PlusCircleOutlined /> Create Auction</Button> */}
      </div>

      <Modal open={createModalOpen}
        footer={null}
        destroyOnClose
        onCancel={handleModalCancel}
        closable>
        <div className={styles.modal}>
          <div className={styles.modal_header}>
            <h2>Put Up For Auction</h2>
            <CloseOutlined style={{ color: '#fff', fill: '#fff' }} onClick={() => setCreateModalOpen(false)} />
          </div>
          <div className={styles.body}>
            <Select
              placeholder="Select Your Track"
              options={myTracks?.map((track) => ({
                value: track.value,
                label: renderLabel(track.label), // Используется для рендера
                fullLabel: track?.label, // Полная информация для сохранения
                name: track?.label?.name, // Отображение в инпуте
              }))}
              optionLabelProp="name"

              style={{ width: '100%' }}
              onSelect={(value, option) => setSelectedValue({ label: option.fullLabel, value: value })} // Сохраняем всю информацию из fullLabel
            />
          </div>
          {
            selectedValue !== null && (
              <div className={styles.additional_info}>
                <img className={styles.inner_image} src={selectedValue?.label?.cover} />
                <p className={styles.text_info}>Track: {selectedValue?.label?.name}</p>
                <p className={styles.text_info}>Artist: {selectedValue?.label.artists.map((artist) => artist.name).join(', ')}</p>
                <p className={styles.text_info}>Genre: {selectedValue?.label.genre.map((genre) => genre.name).join(', ')}</p>
                <p className={styles.text_info}>Price: {sprintf('%.2f',selectedValue?.label.price)} VST</p>
                <p className={styles.text_info}>Auction Duration: {moment().format('DD MMMM YYYY')} - {moment().add(7, 'days').format('DD MMMM YYYY')}</p>
                <Input onChange={(e) => { setStartPrice(parseInt(e.target.value)) }} className={styles.price_input} type="number" placeholder="Starting Price" />
                <Button onClick={() => { startAuction() }} className={styles.start_button}><Bid className={styles.auction_icon} />Start Auction</Button>
              </div>
            )
          }
        </div>

      </Modal>

      {

        isTracksLoading ? <></> :
        currentView === 0 && (
          <List
          pagination={{
            current: pageAll,
            pageSize: perAll,
            position: 'bottom',
            align: 'center',
            total: data?.meta?.total,
            onChange: (pageAll, pageSizeAll) => {
              setPageAll(pageAll);
              setPerAll(pageSizeAll);
            },
          }}
          dataSource={data?.data}
          renderItem={(item, index) => (
            <AuctionItem onJoin={onJoin}  type={'start'} data={item}/>
          )}
          >

          </List>
        )
      }

      {
        currentView === 1 && (
          <List
          pagination={{
            current: page,
            pageSize: per,
            position: 'bottom',
            align: 'center',
            total: yourAuctions?.meta?.total,
            onChange: (page, pageSize) => {
              setPage(page);
              setPer(pageSize);
            },
          }}
          dataSource={yourAuctions?.data}
          renderItem={(item) => (
            <AuctionItem  type={'involved'} data={item}/>
          )}
          >

          </List>
        )
      }


    </ProfileLayout>
  )
}


export default Auction;
