import React from "react";

import styled from './HallOfFame.module.scss'
import trophy from '../../assets/musicbet/trophy.svg'

interface HallProps {
 index: number,
 name: string,
 points: number,
 status: string,
 image?: string
}

const HallOfFame = (props: HallProps) => {
 return (
  <div className={styled.wrapper}>
   <div>
    <p className={styled.index}>{props.index}</p>
   </div>
   <div className={styled.image_wrapper}>
    <img className={styled.avatar} src={props.image} alt={'avatar'} />
   </div>
   <div className={styled.info_wrapp}>
    <p className={styled.name}>{props.name}</p>
    <div className={styled.tropeys}>
     <img className={styled.small_info_left} src={trophy} alt="trophy"/>
     <p className={styled.small_info_left}>{props.points} Wins</p>
    </div>
   </div>
  </div>
 )
}

export default HallOfFame;
