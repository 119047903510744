import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { NotificationT } from '../../components/ToastifyNot/NotificationToastify';
import { authAPI } from '../../api/api';

import { Login } from './Login';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { toggleLoginScene, closeLoginModalMenu } from '../../store/loginModalSlice';
import { userLogIn } from '../../store/isAuthSlice';

import styled from './Login.module.scss';
import { Button, Form, Input } from 'antd';


import { useNavigate } from 'react-router-dom';
import MusicHeader from '../../components/MusicHeader';
import { useState } from 'react';
import { SignIn } from './SignIn';
import { EyeFilled, EyeInvisibleOutlined } from '@ant-design/icons';

interface IInitial {
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  c_password?: string;
  phone?: string;
  nickname?: string
}

const Registration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [authType, setAuthType] = useState("");
  const [isConfVis, setConfVis] = useState(false)
  const [isPassVis, setPassVis] = useState(false)



  function sendDataReg(values: IInitial) {
    authAPI
      .signUp(
        values.nickname,
        values.email,
        values.password,
        values.c_password,
      )
      .then((res) => {
        dispatch(userLogIn(null));
        toast.success("Now login using your username and password")
        setTimeout(() => navigateToSignIn(), 2000);
      })
      .then((res) => {
        authAPI
          .logIn(values.email, values.password)
          .then(() => {
            dispatch(userLogIn(null));
          })
          .then(() => {
            authAPI
              .authMe()
              .then(() => {
                localStorage.setItem('isAuth', "true")
                navigate('/genres');
                dispatch(closeLoginModalMenu(null));
              })
              .catch(() => {
                navigate("/login")
              })
          })
          .catch((error) => {
            if (error.response) {
              toast.error('Wrong email or password, please try again');
            } else {
              toast.error('Something went wrong, please try again');
            }
          });
      })
      .catch((error) => {
        if (error.response.data.nickname) {
          toast.error(error?.response?.data?.nickname?.[0]);
        } else if (error.response.data.email) {
          toast.error(error.response.data.email[0]);
        }
        // else if (error.response.data.phone) {
        //   toast.error(error.response.data.phone[0]);
        // }
        else {
          toast.error('Something went wrong, please try again');
        }
      });
  }

  function navigateToSignIn() {
    dispatch(toggleLoginScene(null));
  }

  return (
    <div style={{ background: "#000", width: '100%' }}>
      {/* <NotificationT /> */}

      {authType === "" ?
        <>
          <MusicHeader title='SIGN UP' primaryColor='#A8908D' color='#000' />
          <Form
            layout="vertical"
            onFinish={(values) => { sendDataReg(values) }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ width: "100%", marginTop: 40 }}
          >
            <Form.Item
              name={"nickname"}
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Insert username",
                },
              ]}
            >
              <Input placeholder='Username*' />
            </Form.Item>
            <Form.Item
              name={"email"}
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Insert a correct email",
                },
              ]}
            >
              <Input placeholder='Email*' />
            </Form.Item>
            <Form.Item
              required
              name={"password"}
              rules={[{ required: true, message: "insert the password" }]}
            >
              <Input.Password placeholder='Password*'
                visibilityToggle={{ visible: isPassVis, onVisibleChange: setPassVis }}
                iconRender={(visible) => (visible ? <EyeInvisibleOutlined style={{ color: '#fff', fill: '#fff' }} /> : <EyeFilled style={{ color: '#fff', fill: '#fff' }} />)}

              />
            </Form.Item>

            <Form.Item
              required
              name={"c_password"}
              rules={[{ required: true, message: "insert the password" }]}
            >
              <Input.Password
                visibilityToggle={{ visible: isConfVis, onVisibleChange: setConfVis }}
                iconRender={(visible) => (visible ? <EyeInvisibleOutlined style={{ color: '#fff', fill: '#fff' }} /> : <EyeFilled style={{ color: '#fff', fill: '#fff' }} />)}
                placeholder='Confirm Password*' />
            </Form.Item>

            <div className={styled.btn}>
              <Button
                htmlType="submit"
                type={"default"}
                className={styled.btn2}
              >
                <span className={styled.submitBtn}>Send</span>
              </Button>
              <Button
                onClick={() => setAuthType('login')}
                type={"default"}
                className={styled.btn1}
              >
                Have an Account? Login
              </Button>
            </div>
          </Form>
        </>
        :
        <SignIn />}
    </div>
  );
};

export { Registration };
