/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import MusicHeader from "../../components/MusicHeader";
import styles from './MusicTournament.module.scss'

import { Col, Row } from "antd";
import BattleItem from "../../components/BattleItem";
import BattleDivider from "../../components/BattleDivider";
import MusicBattleItem from "../../components/BattleItem";

import mockImage from '../../assets/mock/battle.jpg'
import { genresAPI, musicAPI } from "../../api/api";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";

const MusicTournament = () => {

  const [genres, setGenres] = useState<any>()
  const [tournament, setTournament] = useState<any>()
  const [loading, setLoading] = useState(true)
  const [genreInfo, setGenreInfo] = useState<any>()
  const [searchParams] = useSearchParams();
  const colorId = searchParams.get('id')

  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  useEffect(() => {
    genresAPI
      .getGenres()
      .then((res) => {
        setGenres(res)
        console.log(res)
        const genre = res.find((item) => {
          return item.id.toString() === colorId
        })
        setGenreInfo(genre)
      })
      .then(() => {
        musicAPI.getTournamentsById(colorId).then((res) => setTournament(res?.data[0])).then(() => setLoading(false))
      })
      .then(() => {
        if (localStorage.getItem('isAuth')) {
          toast.info("Join any ongoing battle. Don't miss your chance to vote for the winner!")
        } else {
          toast.error("Login or Register to see battle")

        }
      })
  }, [colorId])

  if (loading) {
    return null;
  }

  console.log(tournament?.battles[6]?.state)

  return (
    <div>
      {!isDesktop && <MusicHeader returnable title={`${genreInfo.name}`} primaryColor={genreInfo?.data?.primary_color} color="#fff" />}

      <div className={styles.wrapper}>
        <Row justify={'center'}>
          <Col span={11} style={{cursor: 'pointer'}}>
            <MusicBattleItem
              display_id={1}
              id={tournament?.battles[0]?.id}
              image={tournament?.battles[0]?.data?.cover}
              end_date={tournament?.battles[0]?.started_at}
              primaryColor={genreInfo.data?.primary_color}
              secondaryColor={genreInfo.data?.secondary_color}
              idk={genreInfo.data?.cover}
              data={tournament?.battles[0]}
            />

          </Col>
          <Col span={11} style={{cursor: 'pointer'}}>
            <MusicBattleItem
              display_id={2}
              id={tournament?.battles[1]?.id}
              image={tournament?.battles[1]?.data?.cover}
              end_date={tournament?.battles[1]?.started_at}
              primaryColor={genreInfo.data?.primary_color}
              secondaryColor={genreInfo.data?.secondary_color}
              idk={genreInfo.data?.cover}
              data={tournament?.battles[1]}
            />

          </Col>
        </Row>
        <Row justify={'center'}>
          <Col span={11} style={{cursor: 'pointer'}}>
            <MusicBattleItem
              display_id={5}
              id={tournament?.battles[4]?.id}
              image={tournament?.battles[4]?.data?.cover}
              end_date={tournament?.battles[4]?.started_at}
              primaryColor={genreInfo.data?.primary_color}
              idk={genreInfo.data?.cover}
              data={tournament?.battles[4]}
              secondaryColor={genreInfo.data?.secondary_color}
            />
          </Col>
        </Row>
        <Row align={'middle'} style={{height: '100%'}} justify={'center'}>
          <Col span={22} xl={15} style={{cursor: 'pointer'}}>
            <BattleDivider id={tournament?.battles[6]?.id}
              img={tournament?.battles[6]?.data?.cover}
              end_date={tournament?.battles[6]?.started_at}
              state={tournament?.battles[6]?.state}
              display_id={7}
              primaryColor={genreInfo.data?.primary_color}
              idk={ genreInfo.data?.final_cover || genreInfo.data?.cover}
              secondaryColor={genreInfo.data?.secondary_color}
              data={tournament?.battles[6]}
              />
          </Col>
        </Row>
        <Row justify={'center'}>
          <Col span={11} style={{cursor: 'pointer'}}>
            <MusicBattleItem
              display_id={6}
              id={tournament?.battles[5]?.id}
              image={tournament?.battles[5]?.data?.cover}
              end_date={tournament?.battles[5]?.started_at}
              primaryColor={genreInfo.data?.primary_color}
              idk={genreInfo.data?.cover}
              secondaryColor={genreInfo.data?.secondary_color}
              data={tournament?.battles[5]}
            />
          </Col>

        </Row>
        <Row justify={'center'}>
          <Col span={11} style={{cursor: 'pointer'}}>
            <MusicBattleItem
              display_id={3}
              id={tournament?.battles[2]?.id}
              image={tournament?.battles[2]?.data?.cover}
              end_date={tournament?.battles[2]?.started_at}
              primaryColor={genreInfo.data?.primary_color}
              idk={genreInfo.data?.cover}
              secondaryColor={genreInfo.data?.secondary_color}
              data={tournament?.battles[2]}

            />
          </Col>
          <Col span={11} style={{cursor: 'pointer'}}>
            <MusicBattleItem
              display_id={4}
              id={tournament?.battles[3]?.id}
              image={tournament?.battles[3]?.data?.cover}
              end_date={tournament?.battles[3]?.started_at}
              primaryColor={genreInfo.data?.primary_color}
              idk={genreInfo.data?.cover}
              data={tournament?.battles[3]}
              secondaryColor={genreInfo.data?.secondary_color}


            />
          </Col>

        </Row>
      </div>

    </div>
  )
}

export default MusicTournament;
