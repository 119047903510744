import React, { useEffect, useState } from "react";
import { ProfileLayout } from "../../../components/ProfileLayout/ProfileLayout";
import styles from './Withdraw.module.scss';
import { Button, Checkbox, Input, Popover, Table } from "antd";
import moment from "moment";
import { profileAPI, serviceApi } from "../../../api/api";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../../hooks/reduxHooks";
import { setUserData } from "../../../store/playerSlice";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { sprintf } from 'sprintf-js'
import momentTimeZone from "../../../hooks/momentTimeZone";
var WAValidator = require('multicoin-address-validator');


const Withdraw = () => {
  const [crypto, setCrypto] = useState<string | null>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<any>()
  const [isValid, setIsValid] = useState(false)
  const [service, setService] = useState<any>()
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);

  const handleSuccess = () => {
    // Здесь можно добавить логику при успешном выполнении действия
    setVisible(false); // Закрывает поповер
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };


  const [transaction, setTransactions] = useState<any>()

  const handleWalletChange = (val: any) => {
    setCrypto(val.target.value)
    const valid = WAValidator.validate(val.target.value, 'usdt');
    if (valid) {
      setIsValid(valid)
    } else {
      setIsValid(valid)
    }
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <span>#{text}</span>

      ),
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => (
        // <span>{moment(text).format('DD.MM.YYYY')}</span>
        <span>{momentTimeZone(text, 'MMM D, YYYY, HH:mm')}</span>
      )

    },
    {
      title: 'Details',
      dataIndex: 'amount',
      key: 'amount',
      render: (text) => (
        <span>{sprintf('%.2f',text)} VST</span>

      ),
    },

  ];

  useEffect(() => {
    profileAPI.getWithdraw()
      .then((res) => {
        setTransactions(res.data)
      })
  }, [])


  const withdraw = () => {
    profileAPI.witchdraw(amount, crypto)
      .then((res) => {
        toast.success('Success')
      })
      .then(() => {
        handleSuccess()
      })
      .then(() => {
        profileAPI.getWithdraw()
        .then((res) => {
          setTransactions(res.data)
        })
      })
      .then((res) => {
        profileAPI
          .getProfile()
          .then((res) => {
            dispatch(
              setUserData({
                first_name: res?.first_name,
                last_name: res?.last_name,
                nickname: res?.nickname,
                email: res.email,
                gender: res.gender,
                phone: res.phone,
                id: res.id,
                balance: res.balance,
                avatar: res?.avatar,
                first_voted: res?.data?.first_voted,
                income: res?.transaction_sums?.income,
                expense: res?.transaction_sums?.expense,
                transactions: res?.videobet_transactions,
                stocks: res?.stocks,
                account_id: res.account_id
              })
            )
          })
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        handleSuccess()
      })
  }

  const content = (

    <div className={styles.popover_content}>

      {
      isValid ?
      <>
      <p>Check the correctness of this data:</p>
      <p>Wallet: <strong>{crypto}</strong></p>
      <p>Amount: <strong>{amount}</strong></p>
      <Button onClick={withdraw} className={styles.withdraw_pop}>Collect</Button>
      </>
    :

    <>
  <p>Wallet is not valid</p>
    </>
    }
    </div>
  );

  return (
    <ProfileLayout>
      <div className={styles.buy_tokens_header}>
        <h2 className={styles.buy_tokens_header__text}>COLLECT</h2>
      </div>


      <div className={styles.description}>
        <p>The minimum collectable amount is 150 USDT.
          Enter your crypto wallet address and collectable amount. Your order will be completed within 48 hours.</p>
        <p>Tether USDT (TRC-20) as a payment method, you will incur a fee of 2% of the amount earned</p>
      </div>

      <div className={styles.input_items}>
        <Input
          onChange={(e) => { handleWalletChange(e) }}
          placeholder="Enter your crypto wallet address"
          className={styles.input}
          suffix={!isValid ?
            <Popover trigger={'hover'}
            className={styles.validation_suffix}
            content={<p style={{textAlign: 'center', height: '100%', padding: '5px', borderRadius: '12px',  background: '#fff', color: "#000"}}>Wallet address is not valid</p>}
            >
              <InfoCircleOutlined style={{color: '#FF3131'}}/>
            </Popover>
             :
             <CheckCircleOutlined style={{color: '#a1fecb'}}/>}
        />
        <Input
          onChange={(e) => setAmount(parseFloat(e.target.value) || null)}
          type="number"
          step={1}
          min={service?.withdraws?.low}
          placeholder="0.00 TKN"
          className={styles.input}
        />
        <Popover
        content={content}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
        >
          <Button className={styles.withdraw}>
            Collect
          </Button>
        </Popover>
      </div>
      <div className={styles.table_wrapper}>
        <Table className="deposit_table" dataSource={transaction} columns={columns} />
      </div>
    </ProfileLayout>
  )
};

export default Withdraw;