import { Col, Row } from 'antd';
import { MantineProvider } from "@mantine/core";

import Vimeo from '@u-wave/react-vimeo';


import styled from './GameRules.module.scss';
import { useMediaQuery } from 'react-responsive';
import MusicHeader from '../../components/MusicHeader';

import mock from '../../assets/musicbet/Gameplay animation.jpg'
import { useNavigate } from 'react-router-dom';

const GameRules = () => {

  const navigate = useNavigate();
  const isDesktop = useMediaQuery({
    query: '(min-width: 1201px)',
  });

  return (
    <>

      {!isDesktop && <MusicHeader title='How to Play' primaryColor='#00A91F' color='#fff' />}

      <div className={styled.wrapper}>
        <Row >
          <Col span={24}>
          </Col>
        </Row>
        <Row className={styled.rules_container}>
          <Col span={24} style={{ marginBottom: 32 }}>
            <Vimeo
              responsive
              video={'https://vimeo.com/1043083029?share=copy'}
              className={styled.player}
              // width={isDesktop ? "100%" : 'max-content !important'}
              // height={isDesktop ? "100%" : 'max-content !important'}
              // style={{width: '100%', height: "100%"}}
              width={isDesktop ? '800px' : "fit-content"}
            // height={isDesktop ? '500px' : "min-content"}
            />
          </Col>
          <Col span={24}>
            <h2 className={styled.subHeader}>
              PERPERTUAL PARTICIPATION:</h2>
            <p className={styled.text}>
              Guaranteed participation of the registered card any time the track is placed in a battle.<br />
              Each track can have only one registered member. <br />
              Standard rules of one-time participation apply. <br /> <br />
              To register any track please check the <a onClick={() => navigate('/store')}>V4S Store</a> or/and <a onClick={() => navigate('/profile/auction')}>Auction.</a> <br />
              All registered cards are stored in the <a onClick={() => navigate('/profile/my-collection')}>My Collection</a> section of the members' account. <br />
              Cards can also be gifted. <br />
              All cards will be featured in tournaments at least once a month. <br />
              The winning card of the tournament is automatically enrolled in the next tournament of the same genre. <br />
              Additionally, the cards are allowed to participate in any of the events on the platform. <br /> <br />
              Gift Cards <br />
              Gift card codes can be sent to the recipient by chosen method of the member. <br />
              The recipient can activate the card after registration on the platform by entering the code in the <a onClick={() => navigate('/profile/my-collection')}>My Collection</a> section. <br /><br />
              Auction <br />
              A card can also be obtained through an active auction.<br />
              Any card holder can initiate an auction at any time.<br />
              The auctions run for 7 days.<br />
              To list a card for an auction the member can choose any amount for a starting bid.<br />
              To join an auction a 10% deposit of the starting bid is required.<br />
              There is 3% commission charge by the platform to the member requesting an auction.<br />
              The commission is based on the final sum of the bid.<br />
              The winning bid has 24 hours to complete the transaction by depositing the total funds minus the 10 % advance. <br />
              The deposit is not refunded in the case of failure to complete the transaction within the given time.<br />
              In which case the card will be offered to the next highest bidder free of charge.<br />
              The deposits will be returned to the remaining bids after 24 hours.<br /><br />
              Sell back<br />
              Cards also can be returned to the platform at their purchase price only after 12 months.<br /><br />
            </p>

            <h2 className={styled.subHeader}>
              ONE-TIME PARTICIPATION:
            </h2>
            <p className={styled.text}>

              Vote4Song is the first world’s fantasy music platform featuring battles, where participants use digital assets to show support for their favorite tracks by voting. <br />
              The platform offers a wide range of musical genres and tournaments. <br />
              Each tournament consists of 8 song playoff.<br />
              There are 3 rounds and 7 battles in each tournament.<br />
              Each battle lasts for 48 hours.<br />
              The results are determined by the number of votes casted for each song.<br />
              The winner of each battle progresses to the next round, while the loser is eliminated from the tournament.
              <br /><br />
            </p>

            <h2 className={styled.subHeader}>
              PARTICIPATION:</h2>
            <p className={styled.text}>
              Participants must meet the age requirement according to their jurisdiction in which they reside. <br />
              Access to Vote4Song.com is prohibited in regions where such activities are restricted by local laws. <br /><br />

            </p>

            <h2 className={styled.subHeader}>
              THE PLATFORM’S DIGITAL ASSETS - VSTOKENS:
            </h2>
            <p className={styled.text}>
              The platform’s currency on Vote4Song.com is the VST (Vote Song Token).<br />
              These tokens are exclusive to the Vote4Song.com platform and cannot be exchanged for fiat currency.<br /><br />
            </p>

            <h2 className={styled.subHeader}>
              TOKEN ACQUISITION:
            </h2>
            <p className={styled.text}>
              Tokens can be acquired at the rate of 10 VST = 1 USDT (Tether)</p>
            <h2 className={styled.subHeader}>
              MINIMUM VST TO ENTER A BATTLE:
            </h2>
            <p className={styled.text}>
              The minimum is 1 VST.

            </p>
            <h2 className={styled.subHeader}>
              BATTLE POT DISTRIBUTION:</h2>
            <p className={styled.text}>
              The battle pot is distributed among the participants who vote for the winning track and perpetual card holders.<br />
              VSTokens are distributed as rewards proportional to the amount of VSTokens participants have placed.<br />
              Battles pot distribution structure: <br />
              -80% is distributed among the participants who voted for the winning track, which includes the card holder if the holder has casted a vote<br />
              -10% is rewarded to the perpetual card holder of the winning track of the battle<br />
              -5% is rewarded to the perpetual card holder of the losing track of the battle <br />
              -4% is allocated to the platform as a fee to support the system <br />
              -1% is allocated to the Tournament Grand Pot, which will be distributed exclusively among final round winners, provided that they participated in all 7 tournament battles. <br /><br />
            </p>

            <h2 className={styled.subHeader}>
              COLLECT VST FROM YOUR ACCOUNT:
            </h2>
            <p className={styled.text}>
              You can transfer VSTokens from your V4S account balance by providing the address of your external wallet and specifying the amount of VST to be transferred.</p>

            <h2 className={styled.subHeader}>
              PARTICIPANTS STRATEGY AND SUCCESS:</h2>
            <p className={styled.text}>

              Please note that Vote4Song is an entertainment platform where success is determined by participants' strategy, skills, and knowledge of the music industry. <br />
              To increase your chances of earning rewards, analyze the popularity of musicians in battles, their music, and the strategies and behaviors of other participants. <br />

            </p>

            <h2 className={styled.subHeader}>
              COMPLIANCE:

            </h2>
            <p className={styled.text}>
              Players must comply with local laws, platform rules and ethical standards.
            </p>

            <h2 className={styled.subHeader}>
              DISPUTE RESOLUTION:

            </h2>
            <p className={styled.text}>
            Vote4Song reserves the right to resolve disputes and make final decisions on all matters related to the platform. <br />
            By participating, players agree to comply with the platform’s rules, Terms & Conditions, and Privacy Policy.
              <br /><br />

            </p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export { GameRules };
