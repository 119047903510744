import { ProfileLayout } from '../ProfileLayout/ProfileLayout';
import { useAppSelector } from '../../hooks/reduxHooks';

import s from './ProfileWallet.module.scss';

import peggy from '../../assets/mock/Peggy-Gou-LP-web-e1712588331306.jpg'
import illit from '../../assets/mock/battle.jpg'
import MyMusic from '../MyMusic';
import { useEffect, useState } from 'react';
import { profileAPI } from '../../api/api';
import { Button, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ProfileWallet = () => {
  const [data, setData] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { firstName, lastName, nickname, transactions, sponsor_status, giver_status, player_status } = useAppSelector((state) => state.player);
  const [voucherCode, setVoucherCode] = useState('');

  useEffect(() => {
    profileAPI
      .getTrack()
      .then((res) => {
        setData(res)
      })
      .then(() => {
        setIsLoading(false)
      })
  }, [voucherCode])

  const useVoucher = () => {
    profileAPI
      .useVoucher(voucherCode)
      .then((res) => {
        toast.success('Song added to your collection')
      })
      .then(() => {
        setVoucherCode('')
      })
      .then((res) => {
        profileAPI
        .getTrack()
        .then((res) => {
          setData(res)
        })
        .then(() => {
          setIsLoading(false)
        })
      })
      .catch(() => {
        toast.error('Wrong Code')
      })
  }

  const handleLoad = () => {
    profileAPI
    .getTrack()
    .then((res) => {
      setData(res)
    })
    .then(() => {
      setIsLoading(false)
    })
  }



  return (
    <ProfileLayout>
      <div className={s.buy_tokens_header}>
        <h2 className={s.buy_tokens_header__text}>My Collection</h2>
      </div>

      <div className={s.code}>
      <Input value={voucherCode} onChange={(e) => setVoucherCode(e.target.value)} placeholder='Do you have a gift code?' />
      <Button onClick={useVoucher}>Claim</Button>
      </div>

      {
        isLoading ? <></> :
    data.data.map((item) => {
      return(
        <MyMusic
        image={item.cover}
        genre={item.categories_names[0]?.name}
        artist={item.artists_names[0].name}
        track={item.name}
        year={item.year}
        price={item.price}
        win={item.win_count}
        income={item.income}
        code={item.gift_code}
        id={item.id}
        data={item}
        onBuyBack={handleLoad}
        />
      )

    })
  }
  {
    data?.meta?.total === 0 && <Button onClick={() => navigate('/store')} className={s.marketplace_btn}>V4S Store</Button>
  }
    </ProfileLayout>
  )
};

export default ProfileWallet;
