import React, { useEffect, useState } from "react";
import MusicHeader from "../../components/MusicHeader";
import styles from './Genres.module.scss'
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { genresAPI, serviceApi } from "../../api/api";
import { Fade } from "react-awesome-reveal";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch } from "../../hooks/reduxHooks";
import { openLoginModalMenu } from "../../store/loginModalSlice";
import { openMobileMenu } from "../../store/mobileMenuSlice";
import { toast } from "react-toastify";

const Genres = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const mocks = [
    { id: 1, genre: "Pop", primary_color: '#FF75E6' },
    { id: 2, genre: 'Latin', primary_color: '#FF4B00' },
    { id: 3, genre: "Rock", primary_color: '#F12027' },
    { id: 4, genre: "Hip-Hop", primary_color: '#4F4F2E' },
    { id: 5, genre: 'R&B', primary_color: '#9507FF' },
    { id: 6, genre: 'Country', primary_color: '#A8908D' },
    { id: 7, genre: 'EDM', primary_color: '#BAD131' },
    { id: 8, genre: 'Jazz', primary_color: '#174BEB' },
    { id: 9, genre: 'Classic', primary_color: '#BAAFDD' },
  ]
  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  const [data, setData] = useState<any>()
  const [isLoading, setLoading] = useState(true)
  const [mobile, setMobile] = useState<any>()
  const [desktop, setDesktop] = useState<any>()

  useEffect(() => {
    genresAPI
      .getGenres()
      .then((res) => {
        setData(res)
        setLoading(false)
      })
      .then(() => {
        if (!localStorage.getItem('isAuth')) {
          toast.info('Log in or sign up, choose your genre and join the tournament!')
        }
      })
  }, [])

  useEffect(() => {
    serviceApi
      .getConfig()
      .then((res) => {
        setDesktop(res["genres.banner.desktop"])
        setMobile(res["genres.banner.mobile"])
      })
  })

  if (isLoading) {
    return (
      <div>Loading</div>

    )
  }

  return (
    <div className={styles.wrapper}>
      {!isDesktop && <MusicHeader title="TOURNAMENT GENRES" primaryColor="#B8B8C0" />}
      <div className={styles.banner_wrapper}>
        <img className={styles.banner} src={isDesktop ? desktop : mobile} alt="banner" />
      </div>
      {
        !localStorage.getItem("isAuth") &&
        <Button
        onClick={() => {
          if (isDesktop) {
            navigate('/login')
          } else {
            dispatch(openMobileMenu(null))
            dispatch(openLoginModalMenu(null))
          }
        }}
        className={styles.login_btn}>Login</Button>
      }
      <div className={styles.buttons}>
        {data.map((item) => {
          return (
         <>
            <Button onClick={() => { navigate(`/tournament/?id=${item.id}`) }} className={styles.genre_button} style={{ background: `${item.data.primary_color}` }}>{item.name}</Button>
            <p className={styles.statistic}>{item.name} Tournament Total Votes: {parseInt(item.tournaments_bids_amount) || 0} VST</p>
         </>
          )
        })}
      </div>
    </div>
  )
}

export default Genres;
