import styled from './Battle.module.scss'


import MusicHeader from "../../components/MusicHeader";
import BattleResultsItem from "../../components/BattleResultsItem";

import mockCover from '../../assets/mock/Battle_mokup 2.png'
import { List } from 'antd';
import { PaginationAlign, PaginationPosition } from 'antd/es/pagination/Pagination';
import { useEffect, useState } from 'react';
import { battleAPI, genresAPI, musicAPI } from '../../api/api';
import { useMediaQuery } from 'react-responsive';

const BattleArchive = ({ onlyResults }: { onlyResults?: boolean }) => {
  const [per, setPer] = useState<number>(25)
  const [page, setPage] = useState<number>(1)

  const [genres, setGenres] = useState<any>()
  const [isLoading, setLoading] = useState(true)
  const [genreInfo, setGenreInfo] = useState<any>()
  const [data, setData] = useState<any>()

  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  const colorId = 3

  useEffect(() => {
    genresAPI
      .getGenres()
      .then((res) => {
        setGenres(res)
        const genre = res.find((item) => {
          return item.id.toString() === colorId
        })
        setGenreInfo(genre)
      })
  }, [colorId])


  useEffect(() => {
    battleAPI
      .getResults(per, page)
      .then((res) => {
        setData(res)
      })
      .then(() => {
        setLoading(false)
      })
  }, [per, page])

  interface NormalizedBattleData {
    id: number;
    tournament: any; // Genre of the winning track
    end_date: string; // Formatted end date
    winner: string; // Name of the winning track
    prizePull: number; // Prize amount
    winningPlayers: number; // Number of players
    cover: string; // Cover image URL,
    artist_name: string
    start_date: any,
    loser: string,
    static: string,
    all_artists: string
  }

  const normalizeBattleData = (data: any): NormalizedBattleData => {
    const moment = require('moment-timezone');
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const winnerTrackId = data?.data?.winner_track_id; // Get winner track ID
    const loserTrackId = data?.data?.looser_track_id
    const winningTrack = data?.track1?.id === winnerTrackId ? data?.track1 : data?.track2; // Determine winning track)
    const looserTrack = data?.track1?.id === loserTrackId ? data?.track1 : data?.track2;
    console.log(winningTrack)
    return {
      id: data?.id,
      tournament: winningTrack?.categories_names[0]?.name,
      end_date: moment.tz(data.ended_at, 'UTC').tz(userTimeZone).format('MMM D, YYYY, HH:mm'),
      start_date: moment.tz(data.started_at, 'UTC').tz(userTimeZone).format('MMM D, YYYY, HH:mm'),
      loser: looserTrack?.artists_names[0]?.name,
      winner: winningTrack?.name,
      prizePull: parseFloat(data?.data?.prize_fund),
      winningPlayers: data?.data?.win_users_count,
      cover: data?.data?.cover,
      static: data?.data?.static,
      artist_name: winningTrack?.artists_names[0]?.name,
      all_artists: `${winningTrack?.artists_names[0]?.name} VS ${looserTrack?.artists_names[0]?.name}`
    };
  };
  const formattedData: NormalizedBattleData[] = data?.data?.map(item => normalizeBattleData(item));

  console.log(data)

  if (isLoading) {
    return null
  }
  return (
    <>
      {!isDesktop && <MusicHeader title="BATTLE RESULTS" primaryColor="#F56040" color="#fff" />}

      <div className={styled.content}>
        <List
          pagination={{
            current: page,
            pageSize: per,
            position: 'bottom',
            align: 'center',
            total: data?.meta?.total,
            onChange: (page, pageSize) => {
              setPage(page);
              setPer(pageSize);
            },
          }}

          dataSource={formattedData}
          renderItem={(item, index) => (
            <BattleResultsItem
              static_cover={item.static}
              index={index}
              genre={item.tournament}
              ended_at={item.end_date}
              winner={item.winner}
              prize={item.prizePull || 0}
              cover={item.cover}
              players={item.winningPlayers}
              battle={item.id}
              loser={item.loser}
              artist={item.artist_name}
              started_at={item.start_date}

            />
          )}
        />
      </div>
    </>
  )
}

export default BattleArchive;
