import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import styled from './Navigate.module.scss';
import { Button } from 'antd';

interface ILinkElement {
  name?: string | null;
  svg?: JSX.Element;
  url?: string;
  path: string;
  icons?: boolean
  icon?: any
  link?: string
  legal?: boolean
}

interface IProps {
  menuElement: ILinkElement[];
  legal?: boolean
}

const NavigateElement: React.FC<IProps> = ({ menuElement, legal }, icons?: boolean,) => {
  const location = useLocation();
  const navigate = useNavigate()

  if (icons === true) {
    return <></>
  } else

    return (
      <div>
        {menuElement.map((item: ILinkElement, index: number) => {
          return (
            <Button
              className={
                location.pathname.includes(item.path) ? styled.activeLinkElement : legal ? styled.legalLinkElement :styled.linkElement
              }
              key={index}
              onClick={() => navigate(item.url)}>
              {item.svg === null ?
                <></>
                :
                <div
                  className={location.pathname.includes(item.path) ? styled.activeIcon : styled.icon}>
                  {item.svg}
                </div>
              }

              <div
                className={
                  location.pathname.includes(item.path)
                    ? styled.ActiveElementMenu
                    : styled.elementMenu
                }>
                {item.name && item.name}

              </div>
            </Button>
          );
        })}
      </div>
    )

}


export default NavigateElement;
