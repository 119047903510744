import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import s from './timer.module.scss';
import { ReactComponent as Bolt } from '../../assets/musicbet/icons/timer_play_30dp_FFFFFF_FILL1_wght400_GRAD0_opsz24.svg';

const Timer = ({ end, start }) => {
	const startDate = new Date(start).getTime();
	const endDate = new Date(end).getTime();

	const [[diffDays, diffH, diffM, diffS], setDiff] = useState([0, 0, 0, 0]);
	const [status, setStatus] = useState(""); // "beforeStart" или "afterStart"
	const [tick, setTick] = useState(false);

	const isMobile = useMediaQuery({
		query: '(max-width: 431px)',
	});

	useEffect(() => {
		const now = new Date().getTime();
		let diff;

		if (now < startDate) {
			// Время до начала
			diff = (startDate - now) / 1000;
			setStatus("beforeStart");
		} else if (now < endDate) {
			// Время до конца
			diff = (endDate - now) / 1000;
			setStatus("afterStart");
		} else {
			// Время вышло
			setStatus("finished");
			setDiff([0, 0, 0, 0]);
			return;
		}

		setDiff([
			Math.floor(diff / 86400), // дни
			Math.floor((diff / 3600) % 24),
			Math.floor((diff / 60) % 60),
			Math.floor(diff % 60),
		]);
	}, [tick, startDate, endDate]);

	useEffect(() => {
		const timerID = setInterval(() => setTick((prev) => !prev), 1000);
		return () => clearInterval(timerID);
	}, []);

	const renderTimer = () => {
		return `${diffDays} days ${diffH.toString().padStart(2, '0')}:${diffM.toString().padStart(2, '0')}:${diffS.toString().padStart(2, '0')}`;
	};

	return (
		<Row
			align={'middle'}
			style={{ height: "100%" }}
			className={s.timer}
			justify={"center"}
		>
			<Col span={24} className={s.options}>
				<Bolt className={s.bolt} />
				<span className={s.optionsValue}>
					<div className={s.optionsValueWrapp}>
						{status === "beforeStart" && "Starts in: "}
						{(status === "afterStart" || status === 'finished'  )&& "Ends in: "}
						{renderTimer()}
					</div>
				</span>
			</Col>
		</Row>
	);
};

export default Timer;
