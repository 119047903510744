
const momentTimeZone = (date, format) => {
 const moment = require('moment-timezone');
 const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

 const inputTime = date ? moment(date) : moment();
 const convertedTime = inputTime.tz(userTimeZone).format(format);

 return convertedTime;
};

export default momentTimeZone;
