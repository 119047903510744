import React, { useEffect, useState } from "react";
import styles from './AuctionItem.module.scss'
import { Button, List, Modal, Avatar, Skeleton, Divider, Popover, Row, Col, Input, InputNumber } from "antd";
import moment from "moment";
import 'moment/locale/en-gb'; // Импорт локали (можно использовать 'en-us' или 'en' для других английских вариантов)
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMediaQuery } from "react-responsive";
import { EyeFilled, QuestionCircleFilled } from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { autctionApi, profileAPI } from "../../api/api";
import { toast } from "react-toastify";
import { setUserData } from "../../store/playerSlice";
import { sprintf } from 'sprintf-js'
interface Props {
  data: any,
  type: any,
  onJoin?: any
}

const AuctionItem = (props: Props) => {
  const [amount, setAmount] = useState(null)
  const calculateTimeLeft = () => {
    const target = moment(props?.data?.ended_at);
    const now = moment();

    if (!target.isValid()) {
      console.error('Invalid date provided to CountdownTimer.');
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const difference = target.diff(now);

    if (difference > 0) {
      const duration = moment.duration(difference);
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [props?.data?.ended_at]);

  useEffect(() => {

  }, [amount])

  const [data, setData] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useAppDispatch();

  const handleWatch = () => {
    autctionApi.getAuctionById(props.data.id).then((res) => {
      setData(res)
    })
      .then(() => setModalOpen(true))
      .finally(() => setIsLoading(false))
  }

  const handleJoin = () => {
    autctionApi.payJoin(props.data.id)
      .then(() => toast.success('You Successfully Joined'))
      .then(() => {
        handleWatch()
      })
      .then(() => {
        profileAPI
          .getProfile()
          .then((res) => {
            dispatch(
              setUserData({
                first_name: res?.first_name,
                last_name: res?.last_name,
                nickname: res?.nickname,
                email: res.email,
                gender: res.gender,
                phone: res.phone,
                id: res.id,
                balance: res.balance,
                avatar: res?.avatar,
                first_voted: res?.data?.first_voted,
                income: res?.transaction_sums?.income,
                expense: res?.transaction_sums?.expense,
                transactions: res?.videobet_transactions,
                stocks: res?.stocks,
                account_id: res.account_id
              })
            )
          });
      }
      )
      .then(() =>
        props.onJoin()
      )

  }


  const { userId } = useAppSelector((state) => state.player);


  const [isModalOpen, setModalOpen] = useState(false);

  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  interface TagProps {
    genre: any
  }

  const Tag = (props: TagProps) => {
    return (

      props.genre.map((item) => {
        return (
          <div className={styles.tag}>
            <p>{item.name}</p>
          </div>
        )
      })
    )
  }




  // Установка локали

  // Преобразование дат
  const start = moment(props.data.started_at).locale('en');
  const end = moment(props.data.ended_at).locale('en');

  const startDate = start.format('D MMMM');
  const endDate = end.format('D MMMM');

  const list = data?.auction_bids?.map((item: any) => {
    return { nickname: item?.user?.nickname, avatar: item?.user?.avatar, amount: sprintf('%.2f', item?.amount) }
  })


  const trackBuilder = (artists, name) => {
    if (artists?.length > 1) {
      return `${artists?.name.join(', ')} - ${name}`;
    }
    return artists?.length === 1 ? `${artists[0].name} - ${name}` : null;
  }

  const handleJoinAuction = () => {
    handleJoin();
    setModalOpen(true)
  }

  const handlePayWin = () => {
    autctionApi
      .payWin(data?.id)
      .then(() => {
        setModalOpen(false)
      })
      .then(() => {
        toast.success('Congratulations! Your track added to your collection')
      })
      .catch(() => {
        toast.error('Something is wrong')
      })
  }

  const handleBid = (amount) => {
    autctionApi
      .bidAuction(data?.id, amount)
      .then((res) => {
        autctionApi.getAuctionById(data?.id).then((res) => {
          setData(res)
        })
          .then(() => setModalOpen(true))
          .finally(() => setIsLoading(false))
      })
      .then(() => props.onJoin())
      .then(() => {
        profileAPI
          .getProfile()
          .then((res) => {
            dispatch(
              setUserData({
                first_name: res?.first_name,
                last_name: res?.last_name,
                nickname: res?.nickname,
                email: res.email,
                gender: res.gender,
                phone: res.phone,
                id: res.id,
                balance: res.balance,
                avatar: res?.avatar,
                first_voted: res?.data?.first_voted,
                income: res?.transaction_sums?.income,
                expense: res?.transaction_sums?.expense,
                transactions: res?.videobet_transactions,
                stocks: res?.stocks,
                account_id: res?.account_id
              })
            )
          });
      }
      )

  }


  return (
    <div className={styles.auction}>
      <img className={styles.background_img} src={props.data.track.cover} alt="cover" />
      <div className={styles.wrapper}>

        <div className={styles.info}>
          <Tag genre={props.data.track?.categories_names} />
          <h4 className={styles.header}>{trackBuilder(props.data.track.artists_names, props.data.track.name)}</h4>
          <div>
            <div className={styles.timer}>
              <p className={styles.timer_text}>
               Ends in: <br /> {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
              </p>
            </div>
          </div>
          {/* <p className={styles.date}>{startDate} - {endDate}</p> */}

          {
            props.type === 'involved' ?
              <Button onClick={handleWatch} className={styles.bid_button} style={{ width: "min-content" }}><EyeFilled /></Button>
              :
              props.data.state === 'completed' ? <Button className={styles.bid_button}>Auction is Over</Button>
                :
                props?.data?.may_bid === true ?
                  <div style={{ display: 'flex', flexDirection: 'row', width: '90%' }}>
                    <Button style={{ width: '100%', marginRight: 5 }} className={styles.bid_button}>You Joined</Button>
                    <Button onClick={handleWatch} className={styles.bid_button} style={{ width: "min-content" }}><EyeFilled /></Button>
                  </div>


                  :

                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Popover content={
                      <div>
                        <p style={{ color: '#fff' }}>Join Auction? </p>
                        <Button style={{ width: '100%' }} onClick={handleJoinAuction} className={styles.bid_button}>Join</Button>
                      </div>

                    }>
                      <Button className={styles.bid_button}>Deposit - {props?.data?.register_amount} VST</Button>
                    </Popover>
                    <Button onClick={handleWatch} className={styles.bid_button} style={{ width: "min-content", marginLeft: 10 }}><EyeFilled /></Button>
                  </div>
          }

        </div>
        <div className={styles.cover}>
          <img src={props.data.track.cover} alt="cover" />
          {
            props.data?.state === 'completed' ?
              <h4 style={{ color: '#fff', marginTop: 5 }}>Final bid: {sprintf('%.2f',props?.data?.bid_amount)} VST</h4>
              :
              <h4 style={{ color: '#fff', marginTop: 5 }}>Highest bid: {sprintf('%.2f', props?.data?.bid_amount)} VST</h4>
          }
        </div>

      </div>

      <Modal width={isDesktop ? '60%' : '90%'} open={isModalOpen} onCancel={() => setModalOpen(false)} onOk={() => setModalOpen(false)} closable footer={null} className={styles.modal}>
        <Row className={styles.modal_wrapper}>
          <Col lg={11} sm={24}>
            <div className={styles.cover_modal}>
              <img src={props.data.track.cover} alt="cover" />
            </div>

            <Tag genre={props.data.track?.categories_names} />
            <p className={styles.name_modal}></p>
            {/* <p className={styles.paragraph_modal}>{startDate} - {endDate}</p> */}
            <div className={styles.timer}>
              <p className={styles.timer_text}>
              Ends in: {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
              </p>
            </div>
            {
              data?.may_bid && (
                <div className={styles.fast_buttons}>
                  <Popover content={
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <p style={{ color: '#fff', fontSize: 16 }}>Total Bid: {parseInt(data?.bid_amount) + 10}</p>
                      <Button className={styles.bid_button_fast} onClick={() => handleBid(parseInt(data?.bid_amount) + 10)}>Place Bid</Button>
                    </div>
                  }>
                    <Button className={styles.bid_button_fast}> + 10 VST</Button>
                  </Popover>
                  <Popover
                    content={
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ color: '#fff', fontSize: 16 }}>Total Bid: {parseInt(data?.bid_amount) + 20}</p>
                        <Button className={styles.bid_button_fast} onClick={() => handleBid(parseInt(data?.bid_amount) + 20)}>Place Bid</Button>
                      </div>}
                  >
                    <Button className={styles.bid_button_fast}> + 20 VST</Button>
                  </Popover>
                  <Popover
                    content={
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ color: '#fff', fontSize: 16 }}>Total Bid: {parseInt(data?.bid_amount) + 100}</p>
                        <Button className={styles.bid_button_fast} onClick={() => handleBid(parseInt(data?.bid_amount) + 100)}>Place Bid</Button>
                      </div>}
                  >
                    <Button className={styles.bid_button_fast}> + 100 VST</Button>
                  </Popover>
                  <Popover
                    content={
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <p style={{ color: '#fff', fontSize: 16 }}>Total Bid: {parseInt(data?.bid_amount) + amount}</p>
                        <InputNumber
                        step="0.01"
                        min={0}

                        onChange={(e) => {
                          setAmount(e)
                        }} style={{ marginBottom: '5px' }} placeholder="Your Amount" />
                        <Button onClick={() => handleBid(amount + parseInt(data?.bid_amount))} className={styles.bid_button_fast}>Place Bid</Button>

                      </div>
                    }
                  >
                    <Button className={styles.bid_button_fast}> + Your Amount</Button>
                  </Popover>
                </div>
              )
            }
            {
              data?.may_bid === false && data.may_register === false && (
                <div className={styles.bid_button_disabled}>You Can't Join
                  <Popover content={
                    props.data.user_id === userId ?
                      <p className={styles.popoverInfo}>This is Your Auction</p>
                      :
                      <p className={styles.popoverInfo}> Auction is Over</p>
                  }>
                    <QuestionCircleFilled className={styles.bidButtonSuffix} />
                  </Popover>
                </div>
              )
            }
            {
              data?.may_register === true && (
                <Button style={{ width: '100%' }} onClick={handleJoinAuction} className={styles.bid_button}>Deposit - {data?.register_amount} VST</Button>
              )
            }

            {
              data?.need_pay && (
                <Button style={{ width: '100%' }} onClick={handlePayWin} className={styles.pay_button}>Pay To Get The Card {data?.need_pay} VST</Button>

              )
            }

          </Col>
          <Col lg={11} sm={24} className={styles.list_modal}>
            <h2 style={{ color: '#fff' }}>Bids:</h2>

            <InfiniteScroll
              dataLength={100}
              next={() => { }}
              hasMore={false}
              height={'300px'}
              className={styles.scroll}
              loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
              endMessage={<Divider plain>All bids here</Divider>}
              scrollableTarget="scrollableDiv"
            >
              <List
                dataSource={list}
                renderItem={(item: any, index) => (
                  <List.Item key={index}>

                    <List.Item.Meta
                      style={{ alignItems: 'center' }}
                      avatar={<Avatar src={item?.avatar} />}
                      title={<h4>{item?.nickname}</h4>}
                      description={<p>{item?.amount} VSTs</p>}
                    />
                    <div>#{index + 1}</div>
                  </List.Item>
                )}
              />
            </InfiniteScroll>


          </Col>
        </Row>

      </Modal>
    </div>


  )
}

export default AuctionItem;
