import React, { useEffect, useState } from "react";
import { ProfileLayout } from "../../../components/ProfileLayout/ProfileLayout";
import { Table, TableProps } from "antd";
import styled from './BattleHistory.module.scss'
import { ReactComponent as Swap } from '../../../assets/musicbet/swap_vert.svg'
import mockImg from '../../../assets/mock/Battle_mokup 2.png'
import { profileAPI } from "../../../api/api";
import { useMediaQuery } from "react-responsive";
import { sprintf } from 'sprintf-js'
// const mock: DataType[] = [
//   {
//     id: 1,
//     battle: mockImg,
//     bets: { bet: 100, lose: 120 },
//     details: { bet: 19, battle_id: 10, end: "11.11.11", win: 100 }
//   }
// ];




const BattleHistory = () => {
  const moment = require('moment-timezone');
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formatNumber = (value) => {
    if (typeof value === 'string' || typeof value === 'number') {
      const strValue = value.toString();
      return strValue.startsWith('-') ? strValue : `+${strValue}`;
    }
    return value; // Если не строка и не число, возвращаем как есть
  };


  const isDesktop = useMediaQuery({
    query: "(min-width: 1200px)",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(4); // Хранение текущего размера страницы

  const [data, setData] = useState<any>();

  useEffect(() => {
    profileAPI
      .getBids()
      .then((res) => {
        setData(res.data);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, []);


  const outputData = data?.flatMap(item => {
    const track1Id = item?.track1?.id;
    const track2Id = item?.track2?.id;

    const bidTrack1 = item?.current_user_bids_amount[track1Id];
    const bidTrack2 = item?.current_user_bids_amount[track2Id];

    const artist1 = item?.track1?.artists_names?.[0]?.name;
    const artist2 = item?.track2?.artists_names?.[0]?.name;

    const winnerTrackId = item?.data?.winner_track_id;
    const loserTrackId = item?.data?.looser_track_id;

    const winnerArtistName = winnerTrackId === track1Id
      ? artist1
      : winnerTrackId === track2Id
        ? artist2
        : "Unknown Artist";

    const loserArtistName = loserTrackId === track1Id
      ? artist1
      : loserTrackId === track2Id
        ? artist2
        : "Unknown Artist";

    const baseItem = {
      id: item?.id,
      battle: {
        cover: item?.data?.cover || "Unknown Cover",
        static: item?.data?.static || "",
        track1ArtistName: artist1,
        track2ArtistName: artist2,
      },
      details: {
        bet: item?.category?.name,
        battle_id: item?.id,
        start: item?.ended_at
        ? `${moment.tz(item.started_at, 'UTC').tz(userTimeZone).format('MMM D, YYYY, HH:mm')}`
        : "Unknown Date",
        end: item?.ended_at
        ? `${moment.tz(item.ended_at, 'UTC').tz(userTimeZone).format('MMM D, YYYY, HH:mm')}`
        : "Unknown Date",
        voteAmount: Object.values(item.current_user_bids_amount).reduce((acc: any, val: any) => parseInt(acc) + parseInt(val)),
        winner: winnerArtistName,
        loser: loserArtistName,
        myVote: [bidTrack1 ? artist1 : null, bidTrack2 ? artist2 : null].filter(Boolean).join(" & "),
        myResult: sprintf('%.2f', formatNumber(item?.current_user_bids_balance)),
      }
    };

    const grandWinItem = item?.current_user_prize_battle_grand_win
      ? {
        ...baseItem,
        id: `${item?.id}`, // Уникальный идентификатор
        details: {
          ...baseItem.details,
          grand_pot: `${sprintf('%.2f', item.current_user_prize_battle_grand_win)} VST`
        }
      }
      : null;

    return grandWinItem ? [grandWinItem, baseItem] : [baseItem];
  });


  console.log(outputData)

  const columns: TableProps['columns'] = [
    {
      title: <span style={{marginLeft: 5}}>No.</span>,
      sortIcon: ({ sortOrder }) => <Swap className={styled.swap} order={sortOrder} />,
      dataIndex: 'id',
      width: '10%',
      key: 'id',
      render: (text, record) => (
        <div className={record?.id % 2 === 0 ? styled.evenRow : styled.oddRow}>
          <p>{text}</p>
        </div>
      ),
      sorter: (a, b) => b?.id - a?.id,
    },
    {
      title: 'Battle',
      dataIndex: 'battle',
      key: 'battle',
      render: (battle, record) => {
        // Проверяем условие для изменения строки
        const isGrandWin = record?.details?.grand_pot;

        if (isGrandWin) {
          // Новый вид строки для Grand Win
          return (
            <div className={styled.image_wrapp}>
              <img className={styled.image} src={battle.cover} alt="cover" />
              <p style={{ textAlign: 'center', fontWeight: 500, marginTop: 10 }}>
              </p>
            </div>
          );
        }

        // Обычный вид строки
        return (
          <div className={styled.image_wrapp}>
            <img className={styled.image} src={battle.static || battle.cover} alt="cover" />
            <p style={{ textAlign: 'center', fontWeight: 500, marginTop: 10 }}>
              {battle.track1ArtistName} & <br /> {battle.track2ArtistName}
            </p>
          </div>
        );
      }

    },
    // {
    //   title: 'Bets',
    //   dataIndex: 'bets',
    //   width: 60,
    //   key: 'bets',
    //   render: (bets) => (
    //     <div>
    //       <p>VST: {bets?.bet} </p>
    //       <p>{bets?.lose}</p>
    //     </div>
    //   )
    // },
    {
      title: 'My Votes',
      dataIndex: 'details',
      key: 'details',
      // width: '30%',
      render: (details, record) => {

        const isGrandWin = record?.details?.grand_pot;

        if (isGrandWin) {
          return (
            <div className={styled.result} style={{ padding: 5 }}>
              <p className={styled.result}>Tournament: {details?.bet}</p>
              <p className={styled.result}>Date: {details?.end}</p>
              <p className={styled.result}>Grand Pot: {details?.grand_pot}</p>

            </div>
          )
        }
        return (
          <div style={{ padding: 5 }}>
            <p className={styled.result}>Tournament: {details?.bet}</p>
            <p className={styled.result}>Battle#: {details?.battle_id}</p>
            <p className={styled.result}>Start Date: {details?.start}</p>
            <p className={styled.result}>End Date: {details?.end}</p>
            <p className={styled.result}>Voted for: {details?.myVote}</p>
            <p className={styled.result}>My Vote: {sprintf('%.2f', details?.voteAmount)} VST</p>
            <p className={styled.result}>Loser: {details?.loser}</p>
            <p className={styled.result}>Winner: {details?.winner}</p>
            <p className={styled.result}>My Result: {details?.myResult} VST</p>
          </div>
        )
      }
    }
  ];


  return (
    <ProfileLayout>
      <div className={styled.table_wrapper}>
        <Table
          dataSource={isLoading ? [] : outputData}
          className={styled.table}
          columns={columns}
          pagination={{
            pageSize, // Используем размер страницы из состояния
            showSizeChanger: true, // Включаем изменение размера страницы
            position: ['bottomCenter'],
            pageSizeOptions: ['4', '8', '16'],
            onShowSizeChange: (current, size) => setPageSize(size), // Обработчик изменения размера страницы
          }}
        />
      </div>
    </ProfileLayout>
  );
}

export default BattleHistory;
