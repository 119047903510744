import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Row, Col, Typography, Space } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";
import cx from "classnames";
import moment from 'moment';

import bg from "./bg.png";
import first from "./first.png";
import play from "./play.png"
import styles from "./styles.module.scss";

const { Title } = Typography;

const Node = ({ size, title = "", image = "", isBottom = false, isChildren = false, mixed = false }) => {
    const isMobile = useMediaQuery({
        query: "(max-width: 1366px)",
    });

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: "relative", zIndex: 2, justifyContent: "center" }}>
            {
                !isBottom && !isChildren && (
                    <Title
                        level={5}
                        style={{
                            textAlign: "center",
                            fontSize: isMobile ? 14 : 18,
                            fontStyle: "italic",
                            opacity: +!!title,
                            whiteSpace: "nowrap"
                        }}
                    >
                        {title || "placeholder"}
                    </Title>
                )
            }

            <div
                style={{
                    position: "relative",
                    width: size,
                    height: size,
                    backgroundColor: isBottom ? "rgba(29, 66, 138, 1)" : "rgb(200 16 46 / 100%)",
                    borderRadius: "50%",
                    backgroundImage: `url(${image})`,
                    overflow: "hidden",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    textTransform: "uppercase",
                }}
            >
                {
                    mixed && (
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                backgroundColor: isBottom ? "rgba(29, 66, 138, 1)" : "rgb(200 16 46 / 100%)",
                                borderRadius: "50%",
                                overflow: "hidden",
                                position: "absolute",
                                left: 0,
                                top: 0,
                                mixBlendMode: "multiply"
                            }}
                        />
                    )
                }
            </div>

            {
                isBottom && !isChildren && (
                    <Title
                        level={5}
                        style={{
                            textAlign: "center",
                            fontSize: isMobile ? 14 : 18,
                            fontStyle: "italic",
                            margin: "16px 0",
                            fontWeight: "bold",
                            opacity: +!!title,
                            whiteSpace: "nowrap",
                        }}
                    >
                        {title || "placeholder"}
                    </Title>
                )
            }
        </div>
    )
}

const Header = ({ title = "", date = "", isBottom = false }) => {
    const isMobile = useMediaQuery({
        query: "(max-width: 1366px)",
    });

    return (
        <Row
            justify={"center"}
        // align={"middle"}
        // style={{
        //     flexDirection: "column",
        // }}
        >
            <Col
                span={12}
                style={{
                    padding: isMobile ? "0" : "8px 16px",
                    border: `2px solid ${isBottom ? "#1d428a" : "#c8102e"}`,
                    ...(isBottom ? { borderBottomWidth: 0 } : { borderTopWidth: 0, }),
                    color: isBottom ? "#1d428a" : "#c8102e",
                    textAlign: "center",
                    fontSize: isMobile ? 12 : 18,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    ...(isMobile ? ({}) : (isBottom ? { marginBottom: -40 } : { marginTop: -40 })),
                }}
            >
                {
                    !!title ? (
                        <div style={{ zIndex: 9, position: "relative", textShadow: "white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px" }}>
                            Battle {title}
                        </div>
                    ) : <div style={{ opacity: +!!title }}>placeholder</div>
                }

                {
                    !!date ? (
                        <div>
                            {moment(date).format("DD:MM:YYYY")}
                        </div>
                    ) : <div style={{ opacity: +!!title }}>placeholder</div>
                }
            </Col>
        </Row>
    )
}

const Stages = ({ items, isLoading }) => {
    const navigate = useNavigate();

    const isMobile = useMediaQuery({
        query: "(max-width: 1366px)",
    });
    const isDesktop = useMediaQuery({
        query: "(min-width: 1701px)",
    });

    const stageFirst = items.slice().filter(({ stage }) => stage === 1);
    const stageSecond = items.slice().filter(({ stage }) => stage === 2);
    const stageThird = items.slice().find(({ stage }) => stage === 3);

    function redirectToTheFirstRound(id: number | null): void {
        if (id) {
            navigate(`/battle/?id=${id}`);

            return;
        }

        console.info("Шиш.")
    }



    return (
        <Spin size={"large"} spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 24 }} />}>
            <Col
                span={24}
                style={{
                    // backgroundImage: `url(${bg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "left bottom",
                    paddingTop: 32,
                    // paddingBottom: 32
                }}
            >
                <Row
                    justify={"center"}
                >
                    <Col
                        span={24}
                    >
                        <Row gutter={[16, 16]} justify={"center"}>
                            {
                                stageFirst.slice(0, 2).map(({ id, battle_customers, date_start, state }, idx) => {
                                    return (
                                        <Col
                                            key={`${id}-node-${idx}`}
                                            span={12}
                                            style={{ cursor: "pointer" }}
                                            onClick={redirectToTheFirstRound.bind(null, id)}
                                            title={`Перейти к #${id}`}
                                        >
                                            <Row gutter={{ xs: 0, md: 16, lg: 24 }}>
                                                {
                                                    battle_customers.map(({ id, track }) => {
                                                        return (
                                                            <Col key={`${id}-top`} span={12}>
                                                                <Node
                                                                    size={isMobile ? 90 : 180}
                                                                    title={track.artists_names?.[0]?.name ?? track.name ?? ""}
                                                                    image={track.artists_names?.[0]?.avatar}
                                                                    mixed={state === 'ended'}
                                                                />
                                                            </Col>
                                                        )
                                                    })
                                                }

                                                <Col span={24}>
                                                    <Header title={id} date={date_start} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>

                    <Col span={24}>
                        <Row gutter={[16, 16]} justify={"center"}>
                            {
                                stageSecond.slice(1).map(({ id, battle_customers, date_start, state }, idx) => {
                                    return (
                                        <Col
                                            key={`${id}-node-second-${idx}`}
                                            span={12}
                                            style={{ cursor: "pointer" }}
                                            onClick={redirectToTheFirstRound.bind(null, id)}
                                            title={`Перейти к #${id}`}
                                            className={cx(styles.secondary)}
                                        >
                                            <div style={{
                                                position: "absolute",
                                                bottom: isMobile ? "75%" : "60%",
                                                left: 0,
                                                right: 0,
                                                margin: "auto",
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                padding: "8px 16px",
                                                color: "#c8102e",
                                                textAlign: "center",
                                                fontSize: isMobile ? 12 : 18,
                                                fontWeight: "bold",
                                                textTransform: "uppercase",
                                            }}>
                                                {
                                                    !!id ? (
                                                        <div style={{ marginBottom: 4, zIndex: 9, position: "relative", textShadow: "white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px" }}>
                                                            Battle {id}
                                                        </div>
                                                    ) : <div style={{ opacity: +!!id, marginBottom: 4 }}>placeholder</div>
                                                }

                                                {
                                                    !!date_start ? (
                                                        <div>
                                                            {moment(date_start).format("DD-MM-YYYY")}
                                                        </div>
                                                    ) : <div style={{ opacity: +!!date_start, marginBottom: 4 }}>placeholder</div>
                                                }
                                            </div>

                                            <Row gutter={{ xs: 0, md: 16, lg: 24 }} justify={"center"}>
                                                {
                                                    battle_customers.map(({ id, track }, idx) => {
                                                        return (
                                                            <Col key={id} span={12}>
                                                                <Node
                                                                    isChildren
                                                                    size={isMobile ? 75 : 120}
                                                                    image={track.artists_names?.[0]?.avatar}
                                                                    mixed={state === 'ended'}
                                                                />
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </Row>

                <Space size={64} direction={"vertical"} />

                {
                    !!stageThird && (
                        <Row
                            key={`third-${stageThird?.id}`}
                            justify={"center"}
                        >
                            <Col span={24} style={{ position: "relative" }}>
                                <Row gutter={[24, 24]} justify={"center"} align={"middle"}>
                                    <Col key={stageThird?.id} span={10}>
                                        <Row justify={"end"}>
                                            <img key={play} src={play} alt={""} style={{ maxWidth: "100%", ...(isMobile ? { paddingLeft: 8 } : {}) }} />
                                        </Row>
                                    </Col>

                                    <Col
                                        key={`${stageThird?.id}-third`}
                                        span={4}
                                        style={{ cursor: "pointer" }}
                                        onClick={redirectToTheFirstRound.bind(null, stageThird.id)}
                                        title={`Перейти к #${stageThird.id}`}
                                    >
                                        <Row gutter={[64, 64]}>
                                            {
                                                stageThird?.battle_customers?.map?.(({ id, track }, idx) => {
                                                    return (
                                                        <Col key={id} span={24} className={cx(styles.thirty, { [styles['thirty_is-bottom']]: !!idx })}>
                                                            <Node
                                                                isChildren
                                                                size={isMobile ? 60 : 120}
                                                                image={track.artists_names?.[0]?.avatar}
                                                                isBottom={!!idx}
                                                                mixed={stageThird?.state === 'ended'}
                                                            />
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>

                                    <Col key={stageThird?.id} span={10}>
                                        <Row>
                                            <img key={first} src={first} alt={""} style={{ maxWidth: "100%", ...(isMobile ? { paddingRight: 8 } : {}) }} />
                                        </Row>
                                    </Col>
                                </Row>

                                <div style={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0, margin: "auto", userSelect: "none", pointerEvents: "none", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                    <div style={{ fontSize: 18, fontWeight: "bold", color: "#1d428a" }}>FINAL</div>
                                    <div style={{ fontSize: 18, fontWeight: "bold", color: "rgb(200 16 46 / 80%)" }}>DAY X</div>
                                </div>
                            </Col>
                        </Row>
                    )
                }

                <Space size={64} direction={"vertical"} />

                <Row
                    justify={"center"}
                >
                    <Col span={24}>
                        <Row gutter={[16, 16]} justify={"center"}>
                            {
                                stageSecond.slice(-1).map(({ id, battle_customers, date_start, state }, idx) => {
                                    return (
                                        <Col
                                            key={`${id}-node-second-bottom-${idx}`}
                                            span={12}
                                            style={{ cursor: "pointer" }}
                                            onClick={redirectToTheFirstRound.bind(null, id)}
                                            title={`Перейти к #${id}`}
                                            className={cx(styles.secondary, styles["secondary_is-bottom"])}
                                        >
                                            <Row gutter={{ xs: 0, md: 16, lg: 24 }}>
                                                {
                                                    battle_customers.map(({ id, track }, idx) => {
                                                        return (
                                                            <Col key={id} span={12}>
                                                                <Node
                                                                    isChildren
                                                                    size={isMobile ? 75 : 120}
                                                                    image={track.artists_names?.[0]?.avatar}
                                                                    isBottom
                                                                    mixed={state === 'ended'}
                                                                />
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>

                                            <div style={{
                                                position: "absolute",
                                                top: isMobile ? "75%" : "60%",
                                                left: 0,
                                                right: 0,
                                                margin: "auto",
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                padding: "8px 16px",
                                                color: "#1d428a",
                                                textAlign: "center",
                                                fontSize: isMobile ? 12 : 18,
                                                fontWeight: "bold",
                                                textTransform: "uppercase",
                                            }}>
                                                {
                                                    !!id ? (
                                                        <div style={{ marginBottom: 4, zIndex: 9, position: "relative", textShadow: "white 1px 1px 2px, white -1px 1px 2px, white 1px -1px 2px, white -1px -1px 2px" }}>
                                                            Battle {id}
                                                        </div>
                                                    ) : <div >&nbsp; </div>
                                                }

                                                {
                                                    !!date_start ? (
                                                        <div>
                                                            {moment(date_start).format("DD-MM-YYYY")}
                                                        </div>
                                                    ) : <div >&nbsp; </div>
                                                }
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>

                    <Col
                        span={24}
                    >
                        <Row gutter={[16, 16]} justify={"center"}>
                            {
                                stageFirst.slice(2, 4).map(({ id, battle_customers, date_start, state }, idx) => {
                                    return (
                                        <Col
                                            key={`${id}-node-bottom-${idx}`}
                                            span={12}
                                            style={{ cursor: "pointer" }}
                                            onClick={redirectToTheFirstRound.bind(null, id)}
                                            title={`Перейти к #${id}`}
                                        >
                                            <Row gutter={{ xs: 0, md: 16, lg: 24 }}>
                                                <Col span={24}>
                                                    <Header title={id} date={date_start} isBottom />
                                                </Col>

                                                {
                                                    battle_customers.map(({ id, track }) => {
                                                        return (
                                                            <Col key={id} span={12}>
                                                                <Node
                                                                    size={isMobile ? 90 : 180}
                                                                    title={track.artists_names?.[0]?.name ?? track.name ?? ""}
                                                                    image={track.artists_names?.[0]?.avatar}
                                                                    isBottom
                                                                    mixed={state === 'ended'}
                                                                />
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Spin>
    )
}

export default Stages;
